
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.approval-result {
  // TODO: перелать табы, чтобы прокидывать классы
  .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-check-large) {
    color: $color-green;
  }
  .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-close-large) {
    color: $color-red;
  }
  .esmp-tabs-tab-active {
    .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-check-large) {
      background: $color-green;
    }
    .esmp-tabs-tab__wrapper:has(> .esmp-svg-icon-24-close-large) {
      background: $color-red;
    }
  }
}
